import type { FC } from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Container, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import gtm from '../lib/gtm';

interface UnauthorizedProps {
  message?: string;
}
const Unauthorized: FC<UnauthorizedProps> = (props: UnauthorizedProps) => {
  const { message } = props;
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>Unauthorized</title>
      </Helmet>
      <Box
        sx={{
          alignItems: 'start',
          backgroundColor: 'background.paper',
          display: 'flex',
          minHeight: '100%',
          px: 3,
          py: '80px'
        }}
      >
        <Container maxWidth="lg">
          <Typography
            align="center"
            color="textPrimary"
            variant={mobileDevice ? 'h5' : 'h5'}
          >
            Unauthorized
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            sx={{ mt: 0.5 }}
            variant="subtitle2"
          >
            {
              message || (
                <div>
                  <p>You do not have permission to access the requested resource.</p>
                  <p>Ask your administrator to give you access to this group.</p>
                </div>
              )
            }
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default Unauthorized;
