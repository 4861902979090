import type { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import jwt_decode from 'jwt-decode';
import Unauthorized from 'src/pages/Unauthorized';

interface AuthorizeGuardProps {
  groups?: string[];
  children: ReactNode;
}

const AuthorizeGuard: FC<AuthorizeGuardProps> = (props) => {
  const { groups, children } = props;

  if (!groups || groups.length === 0) {
    return <>{children}</>;
  }

  try {
    const authResult = JSON.parse(localStorage.getItem('authResult'));
    const jwt = jwt_decode(authResult.accessToken) as any;
    const jwtGroups = jwt.groups || [];
    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];
      if (!jwtGroups.includes(group)) {
        console.log(`Not member of group ${group}`);
        return <Unauthorized />;
      }
    }
  } catch {
    console.log('Failed to decode access token');
    return <Navigate to="/dashboard" />;
  }

  return <>{children}</>;
};

AuthorizeGuard.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node
};

export default AuthorizeGuard;
