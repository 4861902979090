/* eslint-disable react/prop-types */
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import { StrictMode, FC } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import App from './App';
import { AuthProvider } from './contexts/AzureAdContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalApplicationInstance } from 'src/common/msal-application-instance';

interface MsalAppProps {
  msalInstance: PublicClientApplication;
}

const MsalApp: FC<MsalAppProps> = ({ msalInstance }) => (
  <MsalProvider instance={msalInstance}>
    <StrictMode>
      <HelmetProvider>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              <BrowserRouter>
                <AuthProvider>
                  <App />
                </AuthProvider>
              </BrowserRouter>
            </SettingsProvider>
          </LocalizationProvider>
        </StyledEngineProvider>
      </HelmetProvider>
    </StrictMode>
  </MsalProvider>
);

ReactDOM.render(
  <StrictMode>
    <MsalApp msalInstance={msalApplicationInstance} />
  </StrictMode>,
  document.getElementById('root')
);
