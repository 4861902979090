import { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import type { User } from '../types/user';
import { useMsal } from '@azure/msal-react';
import { azureAdRequest } from 'src/config';
import {
  getAuthResult,
  saveAuthResult,
  deleteAuthResult,
} from 'src/common/auth-result-storage';
import { AuthenticationResult } from '@azure/msal-browser';

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

export interface AuthContextValue extends State {
  platform: 'AzureAd';
  loginWithPopup: () => Promise<any>;
  logout: () => Promise<any>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
};

type Action = InitializeAction | LoginAction | LogoutAction | RegisterAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'AzureAd',
  loginWithPopup: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const { instance } = useMsal();
  const [state, dispatch] = useReducer(reducer, initialState);

  const PerformLogin = async (
    getAuthResultCallback: () => Promise<AuthenticationResult>
  ) => {
    try {
      console.log('Performing logging in');
      const authResult = await getAuthResultCallback();
      const isAuthenticated = true;
      const { account, idTokenClaims } = authResult;
      const { sub } = idTokenClaims as any;
      saveAuthResult(authResult);
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated,
          user: {
            id: sub,
            avatar: null,
            email: account.username,
            name: account.name,
            plan: 'Premium',
          },
        },
      });
    } catch (err) {
      console.error(err);
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  };

  useEffect(() => {
    const storageAuthResult = getAuthResult();
    if (!storageAuthResult) {
      return;
    }

    const request = {
      scopes: azureAdRequest.scopes,
      account: storageAuthResult.account,
    };
    const authResultCallback = () => instance.acquireTokenSilent(request);
    PerformLogin(authResultCallback);
  }, []);

  const Login = async (): Promise<void> => {
    const request = {
      scopes: azureAdRequest.scopes,
    };
    const authResultCallback = () => instance.loginPopup(request);
    PerformLogin(authResultCallback);
  };

  const loginWithPopup = async (): Promise<any> => Login();

  const logout = async (): Promise<void> => {
    console.log('Logging out');
    await instance.logoutPopup().then(() => deleteAuthResult());
    dispatch({
      type: 'LOGOUT',
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'AzureAd',
        loginWithPopup,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
