import { AuthenticationResult } from '@azure/msal-browser';

const key = 'authResult';

export const getAuthResult = () => JSON.parse(localStorage.getItem(key));

export const saveAuthResult = (authResult: AuthenticationResult) => {
  localStorage.setItem(key, JSON.stringify({
    account: authResult.account,
    idTokenClaims: authResult.idTokenClaims,
    accessToken: authResult.accessToken
  }));
};

export const deleteAuthResult = () => localStorage.removeItem(key);
