import {
  Card, CardContent, Typography
} from '@material-ui/core';
import { FC } from 'react';
import { Link } from 'react-router-dom';

const Welcome: FC = () => (
  <>
    <Card>
      <CardContent>
        <Typography
          color="textPrimary"
          variant="h5"
        >
          Welcome to Support Tool
        </Typography>
        <Typography
          sx={{ mt: 5 }}
          color="textPrimary"
          variant="subtitle2"
        >
          Useful links
        </Typography>
        <Typography
          color="textPrimary"
          variant="subtitle2"
        >
          <Link to="/dashboard/support/signorders/search">Search sign orders</Link>
        </Typography>
      </CardContent>
    </Card>
  </>
);

export default Welcome;
